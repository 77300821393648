import { Component, OnInit, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalAgregarComponent } from '../modal-agregar/modal-agregar.component';
import { Observable, Subscription } from 'rxjs';
import { UtilitiesService } from '../../servicios/utilities.service';

@Component({
  selector: 'app-table-dialog-servicios-orden',
  templateUrl: './table-dialog-servicios-orden.component.html',
  styleUrls: ['./table-dialog-servicios-orden.component.scss'],
})
export class TableDialogServiciosOrdenComponent implements OnInit {

  constructor(
    public dbF: AngularFirestore,
    public dbR: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAgregarComponent>,
    private change: ChangeDetectorRef,
    public uti: UtilitiesService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    for (const k in data) {
      this[k] = data[k];
    }
  }

  ngOnInit() {
    this.cargarCategorias();
  }

  serviciosS: Subscription;
  servicios: any[];
  actualCategoriaKey: string;
  actualTipoKey: string;
  actualTipo: number = 0
  actualCategoria: number = 0;
  prendas: any[] = [];
  canasta = {}
  loadingCategorias: boolean = false;
  categorias: any[] = []
  tipos: any[] = []

  @Input() permitirCambioPrecioUnitario: boolean = false;
  @Input() ordenRef: AngularFirestoreCollection<any>;
  @Input() pedido: Observable<any>;

  prendasRef: AngularFirestoreCollection<any>;
  async cargarCategorias() {
    this.loadingCategorias = true;
    let categoriasSnap = await this.dbR.database.ref("categorias").orderByChild("orden").once("value")
    categoriasSnap.forEach(categoriaSnap => {
      let obj: any = categoriaSnap.val();
      obj.key = categoriaSnap.key;
      this.categorias.push(obj)
    });

    let tiposSnap = await this.dbR.database.ref("tipos").orderByChild("orden").once("value")
    tiposSnap.forEach(categoriaSnap => {
      let obj: any = categoriaSnap.val();
      obj.key = categoriaSnap.key;
      this.tipos.push(obj)
    });

    this.actualCategoriaKey = this.categorias[0].key;
    this.actualTipoKey = this.tipos[0].key;
    console.log(this.actualTipoKey, this.ordenRef.ref)

    this.serviciosS = this.ordenRef.valueChanges().subscribe(data => {
      this.servicios = data;
      data.forEach(s => {
        if (s.precio && this.canasta[s.categoria + s.tipo + s.prenda])
          this.canasta[s.categoria + s.tipo + s.prenda].precio = s.precio;
      });
    });

    await this.cargarProductos(true)
    setTimeout(() => {
      this.loadingCategorias = false;
    }, 100);
  }


  async cargarCategoriasArreglo(evento?: number) {
    this.actualCategoria = evento || 0;
    if (this.tipos.length && this.categorias.length) {
      this.actualCategoriaKey = this.categorias[this.actualCategoria].key;
      this.actualTipoKey = this.tipos[0].key;
    }
  }
  lastEvento: any;
  async cargarProductos(loadingCategorias?: boolean, evento?: number) {

    if (this.lastEvento === evento) return
    else this.lastEvento = evento;

    console.log("comenzar a cargar", evento)
    this.actualTipo = evento || 0;
    if (this.loadingCategorias && !loadingCategorias) return;
    let tipokey = this.tipos[this.actualTipo].key, categoriaKey = this.categorias[this.actualCategoria].key
    this.actualTipoKey = tipokey
    this.prendasRef = this.dbF.collection<any>('prendas', ref => ref.orderBy("orden").where('tipo', '==', tipokey).where("tablaPrecios", "array-contains", categoriaKey));
    let prendas = await this.prendasRef.get().toPromise()
    let docsPrendas = prendas.docs
    this.prendas = []
    for (const key_prenda in docsPrendas) {
      const prenda = docsPrendas[key_prenda];
      let objeto: any = prenda.data()
      /*
      let prendasPreciosRef = this.dbF.collection<any>('prendas/' + prenda.id + "/precios", ref => ref.where('categoria', '==', categoriaKey));
      let preciosSnap = await prendasPreciosRef.get().toPromise()
      preciosSnap.forEach(precioSnap => {
        objeto.precio = precioSnap.data().precio
      });
      let canastaPrecio = await this.ordenRef.doc(categoriaKey + tipokey + objeto.codigo).get().toPromise(), canastaObjeto = canastaPrecio.data()
      if (!canastaPrecio.exists) {
        canastaObjeto = { cantidad: 0, precio: objeto.precio, categoria: categoriaKey, tipo: tipokey, prenda: objeto.codigo }
      }*/
      if (!this.canasta[categoriaKey + tipokey + objeto.codigo] || !this.canasta[categoriaKey + tipokey + objeto.codigo].prenda) {
        let cantidad = 0;
        this.servicios.forEach(ser => {
          if (ser.categoria === categoriaKey && ser.tipo === tipokey && objeto.codigo === ser.prenda) {
            cantidad = ser.cantidad || 0;
            objeto.precio = ser.precio;
          }
        });
        this.canasta[categoriaKey + tipokey + objeto.codigo] = { cantidad: cantidad, categoria: categoriaKey, tipo: tipokey, prenda: objeto.codigo, precio: this.canasta[categoriaKey + tipokey + objeto.codigo] ? this.canasta[categoriaKey + tipokey + objeto.codigo].precio : objeto.precio || null }
      }
      this.prendas.push(objeto)
      this.change.detectChanges()
    }

  }

  async suma(obj: any, valor: number, prenda: any) {
    let tipokey = this.tipos[this.actualTipo].key, categoriaKey = this.categorias[this.actualCategoria].key
    if (obj && obj.cantidad !== undefined) obj.cantidad += valor
    if (obj.cantidad < 0) obj.cantidad = 0;
    let canastaPrecio = await this.ordenRef.doc(categoriaKey + tipokey + prenda.codigo).get().toPromise();
    if (!canastaPrecio.exists)
      await this.ordenRef.doc(categoriaKey + tipokey + obj.prenda).set(obj)
    this.ordenRef.doc(categoriaKey + tipokey + prenda.codigo).update(obj)
  }
  async cambiosSuma(event: number, obj: any, prenda: any) {
    let tipokey = this.tipos[this.actualTipo].key, categoriaKey = this.categorias[this.actualCategoria].key
    if (obj && obj.cantidad !== undefined) obj.cantidad = event
    if (obj.cantidad < 0) obj.cantidad = 0;
    let canastaPrecio = await this.ordenRef.doc(categoriaKey + tipokey + prenda.codigo).get().toPromise();
    if (!canastaPrecio.exists)
      await this.ordenRef.doc(categoriaKey + tipokey + obj.prenda).set(obj)
    this.ordenRef.doc(categoriaKey + tipokey + prenda.codigo).update(obj)
  }

  async cancelar(): Promise<void> {
    return this.dialogRef.close();
  }
  async terminar(data: any): Promise<void> {
    let si = await this.uti.presentAlertConfirm("¿Estás seguro?", "Con esta acción confirmas recibir lo descrito en el listado")
    if (si)
      this.dialogRef.close(true)
    else this.cancelar()
  }



  doubleClick(item: any, key: string): Promise<boolean> {
    return new Promise((res, rej) => {
      if (this.permitirCambioPrecioUnitario) {
        let precio = parseFloat(prompt("¿Cuál es el nuevo precio?", item.precio))
        if (isNaN(precio) || !precio)
          alert("No has ingresado un número")
        else {
          this.ordenRef.doc(key).update({ precio: precio })
          res(true)
        }
      }
      else
        res(false)
    })
  }
}
