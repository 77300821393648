import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { MapLoaderService } from './../../servicios/mapLoader'
import { UtilitiesService } from '../../servicios/utilities.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalAgregarComponent } from '../modal-agregar/modal-agregar.component';

declare var google: any;

@Component({
  selector: 'app-point-dialog',
  templateUrl: './point-dialog.component.html',
  styleUrls: ['./point-dialog.component.scss'],
})
export class PointDialogComponent implements AfterViewInit {
  map: any;
  drawingManager: any;
  myLocation: google.maps.LatLng
  lastPoint: google.maps.LatLng
  lastPol = []
  @Input() modelo: any;
  @Input() point: any;
  @Input() polygon: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAgregarComponent>,
    public uti: UtilitiesService,
    public http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    for (const k in data) {
      this[k] = data[k];
    }
  }

  async ngAfterViewInit() {
    let s;
    try {
      s = await this.http.get("https://api.ipgeolocation.io/ipgeo?apiKey=f613a3eaa17a49a79d4e78567d0328cf").toPromise()
    } catch (error) {
      s = { latitude: 14.59120, longitude: -90.50840 }
    }
    if (s && s.latitude && s.longitude) {
      MapLoaderService.load().then(() => {
        this.myLocation = new google.maps.LatLng(parseFloat(s.latitude), parseFloat(s.longitude))
        this.lastPoint = this.myLocation
        this.map = new google.maps.Map(document.getElementById('map'), {
          center: this.myLocation,
          zoom: 12
        });
        this.getTheGeo();
      })
    }
  }
  async getTheGeo() {
    if (this.polygon) {
      let value = await this.drawPolygon()
      return this.dialogRef.close(value);
    } else {
      if (this.modelo)
        this.lastPoint = new google.maps.LatLng(this.modelo.lat, this.modelo.lng)
      this.drawPoint()
    }
  }
  async terminar() {
    if (this.polygon)
      return this.dialogRef.close(this.lastPol)
    else
      return this.dialogRef.close({ lat: this.lastPoint.lat(), lng: this.lastPoint.lng() });
  }
  cancelar() {
    if (this.polygon)
      this.dialogRef.close(null)
    else
      this.dialogRef.close({ lat: this.lastPoint.lat(), lng: this.lastPoint.lng() })
  }
  createdPolygon: any;
  drawPolygon() {
    return new Promise((res, rej) => {
      let poligono = [], poligonoObject
      if (this.modelo) {
        this.modelo.forEach(mo => {
          var pt = new google.maps.LatLng(mo.lat, mo.lng);
          poligono.push(pt)
        });
        poligonoObject = new google.maps.Polygon({
          paths: poligono,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map: this.map
        });
      }
      google.maps.drawing.DrawingManager
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        polygonOptions: {
          paths: poligonoObject || null,
          fillColor: "#ff0000",
          fillOpacity: 0.10,
          editable: true,
          draggable: true
        },

        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
        map: this.map
      })

      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          let poligono = event.overlay.getPath().getArray()
          let newPol = []
          poligono.forEach(pol => {
            newPol.push({ lat: pol.lat(), lng: pol.lng() })
          });
          this.lastPol = newPol;
          console.log("last")
        }
      });
      google.maps.event.addListener(this.drawingManager, 'polygoncomplete', function (polygon) {
        console.log(polygon)
      });
    });
  }

  drawPoint(): Promise<boolean> {
    return new Promise((res, rej) => {
      var marker = new google.maps.Marker({
        position: this.modelo && this.modelo.lat ? this.modelo : this.myLocation,
        map: this.map,
        title: 'Sucursal',
        draggable: true,
      });

      google.maps.event.addListener(marker, 'drag', (event: any) => {
        this.lastPoint = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng())
      });

      res(true)
    })
  }
}
