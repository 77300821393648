import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

import { environment } from '../environments/environment';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

export function minlengthValidationMessage(err, field) {
  return `Debería tener al menos ${field.templateOptions.minLength} caracteres`;
}

export function maxlengthValidationMessage(err, field) {
  return `Debería tener menos de  ${field.templateOptions.maxLength} caracteres`;
}

export function minValidationMessage(err, field) {
  return `El valor debe ser mayor a ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
  return `El valor debe ser menor a ${field.templateOptions.max}`;
}

import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
// MODALES
import { ModalAgregarComponent } from "./componentes/modal-agregar/modal-agregar.component";
import { ModalListadoClientesComponent } from "./componentes/modal-listado-clientes/modal-listado-clientes.component";
import { TableDialogComponent } from "./componentes/table-dialog/table-dialog.component";
import { PointDialogComponent } from "./componentes/point-dialog/point-dialog.component";

import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list'

import { FileTypeComponent, FileTypeModule, FileTypeValidationMessages } from 'ngx-formly-material-file';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from "@angular/material/sort";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-GT';
import { TableDialogServiciosOrdenComponent } from './componentes/table-dialog-servicios-orden/table-dialog-servicios-orden.component';
import { AlertDialogComponent } from './componentes/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './componentes/confirm-dialog/confirm-dialog.component';
import { MatInputModule, MatButtonModule } from '@angular/material';
registerLocaleData(localeFr, 'es-GT');
export const APP_LOCALE_ID = 'es-GT';

@NgModule({
  declarations: [AppComponent, ModalAgregarComponent, ModalListadoClientesComponent, TableDialogComponent, PointDialogComponent, TableDialogServiciosOrdenComponent, AlertDialogComponent, ConfirmDialogComponent],
  entryComponents: [ModalAgregarComponent, ModalListadoClientesComponent, TableDialogComponent, PointDialogComponent, TableDialogServiciosOrdenComponent, AlertDialogComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollAssist: false,
      scrollPadding: false
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    DragDropModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    FileTypeModule.forRoot({
      dropzoneText: 'Arrastra tu archivo hasta aquí',
      browseFilesButtonText: 'Selecciona un archivo',
    }),
    FormlyModule.forRoot(
      {
        types: [
          { name: 'file', component: FileTypeComponent },
          //{ name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        ],
        validationMessages: [
          { name: 'required', message: 'Este campo es requerido' },
          { name: 'minlength', message: minlengthValidationMessage },
          { name: 'maxlength', message: maxlengthValidationMessage },
          { name: 'min', message: minValidationMessage },
          { name: 'max', message: maxValidationMessage },
          ... new FileTypeValidationMessages(APP_LOCALE_ID).validationMessages.concat([
            { name: 'fileExtension', message: 'No puedes agregar ese tipo de archivo' },
            { name: 'filesize', message: 'El archivo es demasiado grande' },
            { name: 'filenameForbiddenCharacters', message: 'No es posible subir ese archivo' },
            { name: 'minFiles', message: 'Debes subir al menos un archivo' },
            { name: 'maxFiles', message: 'Son demasiados archivos' }
          ])
        ],
      }),
    FormlyMaterialModule,
    HttpClientModule,

  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: APP_LOCALE_ID },
    AngularFireAuthGuard,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
