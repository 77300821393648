import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogRef } from '@angular/material';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Cliente } from "../../clases/cliente";
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-modal-listado-clientes',
  templateUrl: './modal-listado-clientes.component.html',
  styleUrls: ['./modal-listado-clientes.component.scss'],
})
export class ModalListadoClientesComponent {

  usersForm: FormGroup;
  displayedColumns: string[] = ['nit', 'correo', 'nombre', "telefono", 'id', "star"];
  dataSource: MatTableDataSource<any>;
  propiedadFiltro: string = "nombre"
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  clientesRef: AngularFirestoreCollection<any>;
  list: Observable<Cliente[]>;
  listSubscription: Subscription;
  busquedaString: string;
  constructor(
    public dialogRef: MatDialogRef<ModalListadoClientesComponent>,
    public dbF: AngularFirestore,
    private fb: FormBuilder
  ) {
    this.cargarFiltro()

    this.usersForm = this.fb.group({
      busquedaString: null,
      propiedadFiltro: null
    })
  }

  cargarFiltro() {
    this.clientesRef = this.dbF.collection<Cliente>('usuarios',
      q => this.busquedaString ? q
        .where(this.propiedadFiltro, '>=', "" + this.busquedaString)
        .where(this.propiedadFiltro, '<=', "" + this.busquedaString + '\uf8ff').limit(100)
        : q.orderBy("correo").limitToLast(500));
    this.list = this.clientesRef.snapshotChanges(['added', 'removed', 'modified']).pipe(map(changes => changes.map(c => {
      let da: any = c.payload.doc.data();
      da.id = c.payload.doc.id;
      return da;
    }))).pipe(map((data) => { data.sort(this.compare); return data; }))
    this.listSubscription = this.list.subscribe(s => {
      console.log(s.length)
      this.dataSource = new MatTableDataSource(s);
      this.dataSource.sort = this.sort;
      this.listSubscription.unsubscribe()
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  compare(a, b) {
    if (a.correo < b.correo) return -1;
    if (a.correo > b.correo) return 1;
    return 0;
  }

  filtrar() {
    this.busquedaString = this.usersForm.get("busquedaString").value
    this.propiedadFiltro = this.usersForm.get("propiedadFiltro").value
    console.log(this.propiedadFiltro, this.busquedaString)
    this.cargarFiltro();
  }
  borrarFiltro() {
    this.usersForm.reset()
    this.busquedaString = null;
    this.propiedadFiltro = "nombre"
    this.cargarFiltro();
  }
  async terminar(data: any): Promise<void> {

    return this.dialogRef.close(data);
  }
  async cancelar(): Promise<void> {
    return this.dialogRef.close();
  }
}
