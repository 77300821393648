import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController, ActionSheetController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material';
import { AlertDialogComponent } from '../componentes/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../componentes/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  API_ENDPOINT: string;

  constructor(
    public alertController: AlertController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public http: HttpClient,
    public storage: AngularFireStorage,
    public auth: AngularFireAuth,
    public dialog: MatDialog,
    private sheets: ActionSheetController
  ) {
    this.API_ENDPOINT = document.URL.indexOf('http://localhost') === 0 ? "http://localhost:5005/el-siglo/us-central1/api/" : "https://us-central1-el-siglo.cloudfunctions.net/api/"
  }

  errorAuth(e: any) {
    let errorCode = e.code, mensaje = "Algo ha salido mal";
    switch (errorCode) {
      case "auth/claims-too-large":
        mensaje = "El reclamo es muy grande para ser guardado.";
        break;
      case "auth/invalid-argument":
        mensaje =
          "Se proporcionó un argumento no válido para el método de autenticación.";
        break;
      case "auth/invalid-display-name":
        mensaje = "El nombre del usuario no está permitido.";
        break;
      case "auth/invalid-email-verified":
        mensaje =
          "El valor que se proporcionó para la propiedad de usuario emailVerified no es válido.";
        break;
      case "auth/invalid-email":
        mensaje = "El correo ingresado es incorrecto.";
        break;
      case "auth/invalid-password":
        mensaje = "La contraseña proporcionada es incorrecto.";
        break;
      case "auth/invalid-photo-url":
        mensaje = "La fotografía proporcionada es incorrecto.";
        break;
      case "auth/missing-uid":
        mensaje = "El UID no ha sido proporcionado.";
        break;
      case "auth/invalid-uid":
        mensaje = "El UID proporcionado es incorrecto.";
        break;
      case "auth/uid-alread-exists":
        mensaje = "Ya existe un usuario con ese Servido ID.";
        break;
      case "auth/email-already-exists":
        mensaje = "El correo electrónico ya existe.";
        break;
      case "auth/user-not-found":
        mensaje = "El usuario no se ha encontrado.";
        break;
      case "auth/internal-error":
        mensaje = "Un error con el servidor se ha encontrado.";
        break;
      case "auth/wrong-password":
        mensaje =
          "La contraseña no es válida o el usuario no tiene una contraseña.";
        break;
      case "auth/uid-already-exists":
        mensaje = "El Servido ID ya ha sido tomado, por favor, usa otro."
        break;
    }
    console.log(e)
    this.presentAlert("Error", mensaje)
  }

  presentAlert(header: string, message: string, boton?: string) {
    return new Promise((res, rej) => {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '250px',
        data: { header, message, boton }
      });
      dialogRef.afterClosed().subscribe(result => {
        res(result)
      });
    });
  }

  presentAlertConfirm(header: string, message: string) {
    return new Promise((res, rej) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '250px',
        data: { header, message }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        res(result)
      });
    });
  }
  async presentToast(duration?: number, text?: string): Promise<HTMLIonToastElement> {
    return new Promise(async (res, rej) => {
      const toast = await this.toastController.create({
        message: text || "Por favor, espere",
        duration: duration || 2000
      });
      toast.present();
      res(toast)
    });
  }
  public createLoading(duration?: number, text?: string): Promise<HTMLIonLoadingElement> {
    return new Promise((res, rej) => {
      this.loadingController.create({
        message: text || 'Por favor, espere',
        duration: duration || 0
      }).then((l: HTMLIonLoadingElement) => {
        document.getElementById("htmlapp").classList.add("loadingpresented")
        l.onDidDismiss().then(s => {
          document.getElementById("htmlapp").classList.remove("loadingpresented")
        })
        l.present();
        res(l);
      })
    })
  }

  public httpRequest(method: string, getType: boolean, silence: boolean, afterTitle?: string, afterMessage?: string, jsonData?: any, headers?: boolean, responseType?: string): Promise<any> {
    return new Promise(async (res, rej) => {
      let loading: HTMLIonLoadingElement = await this.createLoading()
      try {
        let token: string;
        if (headers) token = await this.auth.auth.currentUser.getIdToken(true)
        let options: any = getType ? {} : { responseType: 'text' }
        if (responseType) options.responseType = responseType;
        if (headers) options.headers = new HttpHeaders().set("authorization", token)
        let requestT = getType ?
          this.http.get(this.API_ENDPOINT + method, options) :
          this.http.post(this.API_ENDPOINT + method, jsonData, options);
        let s: any = await requestT.toPromise()
        await loading.dismiss();
        if (s && s.error && s.error !== 0) // NO HAY ERROR
          rej(s.error)
        else
          res(s)
      } catch (e) {
        await loading.dismiss();
        await this.presentAlert("Un error ha ocurrido", "No fue posible realizar la petición")
        rej(e)
      }
    })
  }

  public getColumns(fields: Array<any>, token?: number | number[]) {
    let columns: Array<any> = [], displayedColumns: Array<string> = [];
    fields.forEach(field => {
      if (!field.hideColumn && !field.respuestas) {
        columns.push({ prop: field.id, name: field.texto, currency: field.currency || false })
        displayedColumns.push(field.id)
      }
      if (field.respuestas) {
        columns.push({ prop: field.id, name: field.texto, respuestas: field.respuestas, select: true })
        displayedColumns.push(field.id)
      }
    });
    if (token)
      if (typeof token === "number" && token === 1)
        displayedColumns.push("acciones")

    return { columns, displayedColumns }
  }

  uploadFile(mainFolder: string, file: any): Promise<string> {
    return new Promise(async (res, rej) => {
      let loading: HTMLIonLoadingElement = await this.createLoading()
      try {
        const fileRef = this.storage.ref(mainFolder);
        await this.storage.upload(mainFolder, file);
        let s = await fileRef.getDownloadURL().toPromise();
        loading.dismiss()
        res(s)
      } catch (error) {
        loading.dismiss()
        rej(error)
      }
    })
  }

  public fields = [
    { id: "no_orden", texto: "No. Orden", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "usuario", texto: "ID Usuario", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/nit", texto: "NIT", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/correo", texto: "Correo del cliente", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/nombre", texto: "Nombre del cliente", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/nombre_factura", texto: "Facturar a nombre de", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/sexo", texto: "Sexo", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "cliente/telefono", texto: "Teléfono", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "mensajero_recoleccion_correo", texto: "Mensajero Recolector", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_recepcion/ciudad", texto: "Recepción Ciudad", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_recepcion/direccion", texto: "Recepción Dirección", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_recepcion/zona", texto: "Recepción Zona", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_recepcion/otras_indicaciones", texto: "Recepción más indicaciones", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "mensajero_entrega_correo", texto: "Mensajero Entrega", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_entrega/ciudad", texto: "Entrega Ciudad", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_entrega/direccion", texto: "Entrega Dirección", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_entrega/zona", texto: "Entrega Zona", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "direccion_entrega/otras_indicaciones", texto: "Entrega más indicaciones", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "fecha", texto: "Creación", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "fecha_recepcion", texto: "Fecha recolección", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "hora_max_recepcion", texto: "Hora máx recolección", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "hora_min_recepcion", texto: "Hora máx recolección", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "fecha_entrega_str", texto: "Fecha entrega", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "hora_min_entrega", texto: "Hora mín entrega", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "hora_max_entrega", texto: "Hora máx entrega", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "sucursal_nombre", texto: "Sucursal Asignada", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "subtotal", texto: "Subtotal", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "descuento", texto: "Descuento", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "total", texto: "Total", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
    { id: "tipo_pago", texto: "Tipo de Pago", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "error_mensaje", texto: "Mensaje de error", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },

    { id: "ultimo_editor_correo", texto: "Último editor", tipo: "L", subtipo: "text", hideColumn: false, disabled: true, export: true, hideDescription: true },
  ]
  userActionFields = [
    { id: "fecha", texto: "Fecha", tipo: "L", subtipo: "date", hideColumn: false },
    { id: "correo", texto: "Correo del usuario", tipo: "M", hideColumn: false },
    { id: "accion", texto: "Acción realizada", tipo: "L", subtipo: "text", hideColumn: false },
  ];
  exportarExcel(data: any, fields?: any[], name?: string) {
    let newJson: any = data.map(rec => {
      let obj = {};
      console.log(rec)
      if (!fields) fields = this.fields
      fields.forEach(field => {
        if (field.export)
          obj[field.texto] = rec[field.id]
      });
      return obj
    });

    /* make the worksheet */
    console.log(newJson)
    let ws = XLSX.utils.json_to_sheet(newJson);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    /* generate an XLSX file */
    XLSX.writeFile(wb, name + ".xlsx");
  }
  getTiposPago(tipo_pago: number): string {
    switch (tipo_pago) {
      case 1:
        return "Efectivo"
      case 2:
        return "Tarjeta"
      case 3:
        return "Crédito"
    }
  }

  async verMapa(ub: any) {
    if (ub.lat && ub.lng) {
      const actionSheet = await this.sheets.create({
        header: '¿Qué App prefieres?',
        buttons: [{
          text: 'Ver en Google Maps',
          handler: () => {
            window.open(`https://www.google.com/maps/search/?api=1&query=${ub.lat},${ub.lng}`, "_system")
          }
        }, {
          text: 'Ver en Waze',
          handler: () => {
            window.open(`https://www.waze.com/ul?ll=${ub.lat}%2C${ub.lng}&navigate=yes&zoom=17`, "_system")
          }
        }, {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
      });
      await actionSheet.present();
    } else {
      this.presentAlert("No se ha guardado la ubicación", `Por favor, revisa la ubicación descriptiva: ${ub.direccion}, ${ub.zona}, ${ub.ciudad}, otras indicaciones: ${ub.otras_indicaciones}`)
    }
  }
}
