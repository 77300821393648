import { Component, OnInit, Input, Inject } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { ModalAgregarComponent } from "./../modal-agregar/modal-agregar.component";
import { UtilitiesService } from '../../servicios/utilities.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss'],
})

export class TableDialogComponent implements OnInit {

  prendas: Observable<any>;
  loaded: boolean = false;

  @Input() referencia: AngularFirestoreCollection<any>;
  @Input() fields: Array<any>;
  @Input() descripcion: string;
  @Input() titulo: string;
  @Input() ordenable: boolean = true;
  @Input() tablePivot: string;

  @Input() hideAgregar: boolean = false;
  @Input() hideAcciones: boolean = false;

  arrKeys: any = {};
  arr: Array<any> = [];
  columns: any[] = [];
  displayedColumns: string[] = [];

  constructor(
    public dbF: AngularFirestore,
    public dbR: AngularFireDatabase,
    public uti: UtilitiesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAgregarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data)
    for (const k in data) {
      this[k] = data[k];
    }
  }

  ngOnInit() {
    let r = this.uti.getColumns(this.fields, 1)
    this.columns = r.columns
    if (this.hideAcciones) r.displayedColumns.splice(r.displayedColumns.indexOf("acciones"), 1)
    this.displayedColumns = r.displayedColumns
    console.log(this.displayedColumns)
    this.cargar()
  }
  cargar() {
    this.prendas = this.referencia.snapshotChanges(['added', 'removed', 'modified']).pipe(
      map(changes => changes.map(c => {
        let da: any = c.payload.doc.data();
        da.id = c.payload.doc.id;
        return da;
      }))
    )
    this.prendas.subscribe(s => {
      let arrKeys: any = {}, arr = [];
      s.forEach(ss => {
        arrKeys[ss.id] = ss
        arr.push(ss)
      });
      this.arrKeys = arrKeys;
      this.arr = arr;
      this.loaded = true;
    })

  }
  agregarTipo(): void {
    const dialogRef = this.dialog.open(ModalAgregarComponent, {
      width: '650px',
      data: {
        titulo: this.titulo,
        fields: this.fields,
        modelo: {}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result && result.modelo) {
        if (this.tablePivot)
          this.referencia.doc(result.modelo[this.tablePivot]).set(result.modelo)
        else
          this.referencia.add(result.modelo)
      }
    });
  }
  borrar(row) {
    console.log(row)
    this.uti.presentAlertConfirm("¿Estás seguro?", "Estás a punto de eliminar el elemento, presiona 'Aceptar', para eliminar").then(s => {
      if (s) {
        this.referencia.doc(row.id).delete()
        this.uti.presentToast(2000, "Eliminado")
      }
    })
  }
  modificar(row) {
    const dialogRef = this.dialog.open(ModalAgregarComponent, {
      width: '450px',
      data: {
        titulo: this.titulo,
        fields: this.fields,
        modelo: row,
        row: row.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.modelo) {
        this.referencia.doc(result.row).update(result.modelo);
        this.uti.presentToast(2000, "Actualizado")
      }
    });
  }

}
