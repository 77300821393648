import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { FileListValidators, FileValidators } from 'ngx-formly-material-file';
import { UtilitiesService } from '../../servicios/utilities.service';

@Component({
  selector: 'app-modal-agregar',
  templateUrl: './modal-agregar.component.html',
  styleUrls: ['./modal-agregar.component.scss'],
})
export class ModalAgregarComponent implements OnInit, OnDestroy {

  fields: Array<FormlyFieldConfig> = [];
  group = new FormGroup({});
  deletedPhoto: any = {}
  constructor(
    public dialogRef: MatDialogRef<ModalAgregarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uti: UtilitiesService
  ) {
    let fields: Array<FormlyFieldConfig> = [];
    this.data.fields.forEach(field => {
      if (!field.hideForm && field.texto)
        fields.push(this.getTipoPreguntaInput(field, data.modelo[field.id]))
      if (field.tipo === "F" && data.modelo[field.id]) {
        this.deletedPhoto[field.id] = data.modelo[field.id];
        data.modelo[field.id] = null;
      }
    });
    this.fields = fields;
  }

  async cancelar(): Promise<void> {
    await this.returnValuesDefault(this.data)
    return this.dialogRef.close();
  }
  async terminar(data: any): Promise<void> {
    if (!this.group.valid) {
      this.uti.presentToast(2000, "Valida la información ingresada")
      return;
    }
    await this.returnValuesDefault(data)
    return this.dialogRef.close(data);
  }
  async returnValuesDefault(data: any) {
    console.log(this.deletedPhoto)
    for (const k in data.modelo) {
      const element = data.modelo[k];
      if (k === "imagen" || k === "logo" || k === "fondo")
        if (Array.isArray(element)) {
          let file = element[0];
          if (file && file.file && typeof file.file.name == 'string') {
            try {
              let ts = (new Date()).getTime();
              let url = await this.uti.uploadFile("archivos/" + ts + file.file.name, file.file)
              data.modelo[k] = url
            } catch (e) {
              console.log(e)
            }
          }
        } else if (typeof data.modelo[k] !== "string" && this.deletedPhoto && this.deletedPhoto[k]) {
          data.modelo[k] = this.deletedPhoto[k]
        }
    }
  }
  ngOnInit() { }
  async ngOnDestroy() {
    await this.returnValuesDefault(this.data)
  }
  getTipoPreguntaInput(myInput: any, modelo): FormlyFieldConfig {
    let field: FormlyFieldConfig = {
      key: myInput.id,
      templateOptions: {
        label: myInput.texto,
        disabled: myInput.disabled,
        required: !(myInput.notRequired || false)
      }
    }
    switch (myInput.tipo) {
      case "M":
        if (myInput.multiple) {
          field.type = "multicheckbox";
          field.templateOptions.multiple = true;
          if (!myInput.hideDescription)
            field.templateOptions.description = 'Presione sobre el campo, luego, seleccione una o más opciones deseadas';
        } else {
          if (!myInput.hideDescription)
            field.templateOptions.description = 'Presione sobre la opción deseada';
          field.type = "radio";
        }
        field.templateOptions.options = []
        for (const key_respuesta in myInput.respuestas) {
          const r = myInput.respuestas[key_respuesta];
          field.templateOptions.options.push({ value: key_respuesta, label: r.texto || r.nombre })
        }
        break;
      case "L":
        switch (myInput.subtipo) {
          case "number":
            field.type = "input"
            field.templateOptions.type = "number";
            field.templateOptions.min = myInput.min || 0;
            field.templateOptions.max = myInput.max || 10000000;
            field.templateOptions.step = 1;
            field.templateOptions.placeholder = "Ingrese un número";
            if (!myInput.hideDescription)
              field.templateOptions.description = 'Presione sobre el nombre del campo, para responder';
            break;
          case "date":
            console.log(modelo)
            field.defaultValue = modelo[myInput.id] || this.formatDate(new Date().getTime(), 1);
            field.type = 'input'
            field.templateOptions.type = "date";
            field.templateOptions.placeholder = "Seleccione una fecha";
            if (!myInput.hideDescription)
              field.templateOptions.description = "Presione sobre la fecha, escriba como día/mes/año en números";
            break;
          default:
            field.type = "input"
            field.templateOptions.type = myInput.subtipo;
            field.templateOptions.minLength = myInput.min || 0;
            field.templateOptions.maxLength = myInput.max || 200;
            field.templateOptions.placeholder = "Ingrese un " + this.returnNameDescription(myInput.subtipo);
            if (!myInput.hideDescription)
              field.templateOptions.description = 'Presione sobre el nombre del campo, para responder';
            break;
        }
        break;
      case "O":
        field.type = "datatable";
        let columns: any[] = [], fieldArray: any[] = [];
        for (const key_respuesta in myInput.respuestas) {
          const r = myInput.respuestas[key_respuesta];

          columns.push({ name: r.texto, prop: key_respuesta })
          fieldArray.push({
            type: 'input',
            //defaultValue: r.subtipo === "date" ? this.formatDate(new Date().getTime(), 1) : null,
            key: key_respuesta,
            templateOptions: {
              required: false,
              type: r.subtipo,
              placeholder: r.subtipo === "date" ? "Presione" : "Escriba " + r.texto
            },
          })
        }
        field.defaultValue = [{}, {}, {}]
        field.templateOptions = { columns };
        field.templateOptions.titulo = myInput.texto
        field.fieldArray = { fieldGroup: fieldArray }
        break;
      case "F":
        field.defaultValue = null;
        field.type = "file";
        field.templateOptions.placeholder = myInput.placeholder;
        field.templateOptions.type = "file";
        field.
          validators = {
          validation: [
            FileListValidators.minFiles(myInput.min),
            FileListValidators.maxFiles(myInput.max),
            FileListValidators.totalFilesize(400 * 1000),
          ]
        }
        console.log(myInput.extension)
        field
          .fieldArray = {
          validators: {
            validation: [
              FileValidators.fileExtension(myInput.extension)
            ]
          }
        }
        field.defaultValue = null
        break;
    }
    return field;
  }

  formatDate(ts: number, tipo?: number): string {
    var today = new Date(ts);
    var dd: any = today.getDate();
    var mm: any = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    switch (tipo) {
      case 1:
        return yyyy + "-" + mm + "-" + dd;
      default:
        return dd + '/' + mm + '/' + yyyy;
    }
  }

  returnNameDescription(tipo: string) {
    switch (tipo) {
      case "text":
        return "texto";
      case "tel":
        return "teléfono";
      case "email":
        return "correo electrónico";
      default:
        return "valor";
        break;
    }
  }

  descargar(pedidoID: string) {
    window.open("https://us-central1-el-siglo.cloudfunctions.net/apipdf/crear?pedidoID=" + this.data.modelo.id, "_system")
  }
}
